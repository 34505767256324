import Konva from 'konva';
import { Wedge } from '../model/wedge';
import Config from "../service/config";
import Game from "../model/game"
import "../style/index.css";
import sound from "../asset/click.mp3";


var game = null

var width = window.innerWidth;
var height = window.innerHeight * .8;

Konva.angleDeg = false;
var angularVelocity = 6;
var lastRotation = 0;
var numWedges = 20;
var angularFriction = 0.1;
var activeWedge, stage, layer, wheel, pointer, border, woman;
var anim;

var isRotating;

var click, audioIndex;

var isTest = false;

function stylizedWoman() {
    var woman = document.getElementById("woman");
    var rect = document.getElementsByClassName('konvajs-content')[0].getClientRects()[0]
    woman.style.height = `calc(${document.getElementsByClassName('konvajs-content')[0].offsetHeight  * .8}px)`
    woman.style.display = "block";
    woman.style.left = `calc(50% + ${document.getElementsByClassName('konvajs-content')[0].offsetHeight / 2 * .60}px)`
    woman.style.bottom = window.innerHeight - rect.bottom + "px";
} 

function play() {
    isTest = false;
    document.getElementById('play').removeEventListener('click', play);
    angularVelocity = 20 + Math.random() * 30;
    if (!game) {
        game = new Game();
        game.cumulBlink(false);
        game.clearHTML()
    }
    if (!anim) anim = new Konva.Animation(animate, layer);
    anim.start();
    isRotating = true;
}

function animate(frame) {
    // handle wheel spin
    var angularVelocityChange =
        (angularVelocity * frame.timeDiff * (1 - angularFriction)) / 1000;
    angularVelocity -= angularVelocityChange;

    // activate / deactivate wedges based on point intersection
    var shape = stage.getIntersection({
        x: stage.width() /  2 + 100,
        y: stage.height() / 2 - 59,
    });

    var diff = (frame.timeDiff * angularVelocity) / 1000;
    if (diff > 0.0005) {
        wheel.rotate(diff);
    } else if (shape) {
        isRotating = false;
        anim.stop();
        const type = shape.getParent().getAttrs()['type'];
        var text = shape.getParent().findOne('#value').text();
        var price = text.split('\n')
        var number = shape.getParent().findOne('#number').text();
        game.updateState(type, parseInt(price, 10), number.toString());
        document.getElementById('play').addEventListener('click', play);
        if (game.isEnd()) {
            console.log("end game")
            game.cumulBlink(true)
            game = undefined;
            anim = undefined;
        };
    }
    lastRotation = wheel.rotation();

    if (shape) {
        if (shape && (!activeWedge || shape._id !== activeWedge._id)) {
            pointer.y(stage.height() / 2 - 120);
            new Konva.Tween({
                node: pointer,
                duration: 0.3,
                y: stage.height() / 2 - 125,
                easing: Konva.Easings.ElasticEaseOut,
            }).play();

            if (audioIndex === 9) audioIndex = 0;
            else audioIndex++;

            click[audioIndex].play()


            if (activeWedge) {
                activeWedge.fillPriority('radial-gradient');
                activeWedge.getParent().findOne("#led").fillPriority('radial-gradient');
            }

            shape.fillPriority('fill');
            shape.getParent().findOne("#led").fillPriority('fill');
            activeWedge = shape;
        }
    }
}

function init() {
    const roueConfig = Config.getConfig();

    audioIndex = 0;

    click = [
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound),
        new Audio(sound)
    ]

    stage = new Konva.Stage({
        container: 'container',
        width: width,
        height: height,
    });

    layer = new Konva.Layer();
    wheel = new Konva.Group({
        x: stage.width() / 2,
        y: stage.height() / 2,
    });

    pointer = new Konva.Wedge({
        fillRadialGradientStartPoint: {
            x: 0,
            y: 0
        },
        fillRadialGradientStartRadius: 0,
        fillRadialGradientEndPoint: {
            x: 0,
            y: 0
        },
        fillRadialGradientEndRadius: 30,
        fillRadialGradientColorStops: [0, 'white', 1, 'red'],
        stroke: 'white',
        strokeWidth: 2,
        lineJoin: 'meter',
        angle: 1,
        radius: 30,
        x: stage.width() / 2 + 230,
        y: stage.height() / 2 - 120,
        rotation: -45,
    });

    pointer.listening(false);
    pointer.cache()

    border = new Konva.Circle({
        radius: 270,
        fill: 'transparent',
        stroke: '#ccc',
        strokeWidth: 3,
        fill: "rgb(245, 229, 27)",
        x: 0,
        y: 0,

    })
    border.cache()
    border.listening(false)
    wheel.add(border)
    for (var n = 0; n < numWedges; n++) {
        const wedge = new Wedge(numWedges, n, roueConfig[n].type, roueConfig[n].value)
        wheel.add(wedge.group);
    }
    
    // add components to the stage
    // wheel.cache()
    layer.add(wheel);
    layer.add(pointer);
    stage.add(layer);
    window.setTimeout(stylizedWoman, 10)


    document.getElementById('play').addEventListener('click', play);
}


init();
