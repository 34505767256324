export default class Game {
    cumul;
    cumulHTML;
    number;
    numberHTML;
    launch;
    launchHTML;
    bonus;
    bonusHTML;
    isBonusUsed;
    constructor() {
        this.cumul = 0;
        this.cumulHTML = document.getElementById('cumul');
        this.launch = 0;
        this.launchHTML = document.getElementById('launch');
        this.bonus = 1;
        this.numberHTML = document.getElementById('number')
        this.number = ""
        this.bonusHTML = document.getElementById('bonus');
        this.updateFinalCountBonusHTML(false)
        this.isBonusUsed = false;
    }

    updateState(type, price, number) {
        if (!this.isBonusUsed) this.isBonusUsed = true;
        this.launch++;
        this.number += number.toString();
        switch (type) {
            case "win":
                this.cumul += (price * this.bonus);
                break;
            case "crash":
                this.cumul = 0;
                break;
            case "passe":
                break;
            case "chance":
                if (this.launch < 5) {
                    this.bonus = this.bonus * 2;
                    this.isBonusUsed = false;
                    this.updateBonusHTML()
                } else {
                    this.cumul = this.bonus * this.cumul
                }
                break;
            case "super chance":
                if (this.launch < 5) {
                    this.bonus = this.bonus * 3;
                    this.isBonusUsed = false;
                    this.updateBonusHTML()
                } else {
                    this.cumul = this.bonus * this.cumul
                }
                break;
        }
        if (this.isBonusUsed && this.bonus > 1) {
            this.bonus = 1;
            this.updateBonusHTML()
        }

        this.updateHTML()
    }

    updateHTML() {
        this.numberHTML.innerHTML = this.number;
        this.cumulHTML.innerHTML = this.cumul + " €";
        this.launchHTML.innerHTML = this.launch + "/5"
    }

    clearHTML() {
        this.numberHTML.innerHTML = "";
        this.cumulHTML.innerHTML = "";
        this.launchHTML.innerHTML = "";
    }


    updateBonusHTML() {
        if (this.bonus > 1) this.bonusHTML.innerHTML = "BONUS <br/>" + this.bonus;
        else this.bonusHTML.innerHTML = "";
    }

    updateFinalCountBonusHTML(toggle) {
        this.bonusHTML.innerHTML = toggle ? "GAIN <br/>" + this.cumul + " €": "";
        return toggle ?  this.bonusHTML.classList.add("blink") : this.bonusHTML.classList.remove("blink")

    }

    isEnd() {
        if (this.launch === 5) this.updateFinalCountBonusHTML(true)
        return this.launch === 5;
    }

    cumulBlink(toggle) {
        return toggle ? this.cumulHTML.classList.add("blink") : this.cumulHTML.classList.remove("blink")
    }

}